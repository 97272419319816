import { withComponentSetup } from 'src/components/Auth/_lib/ComponentSetup';
import SignIn from '../SignInMAM';
import { ContentType } from './_content';

const resolver = (props: ContentType) => {
  return {
    guid: props.dataSource,
    cancelButtonText: props.fields['Cancel Button Text'].value,
    cancelButtonLink: props.fields['Cancel Button Link'].value,
    continueButtonText: props.fields['Continue Button Text'].value,
    previousButtonLink: props.fields['Previous Button Link'].value,
    previousButtonText: props.fields['Previous Button Text'].value,
    disableOnMaintenance: props.fields['Disable on Maintenance'].value,
    endpoint: props.fields.Endpoint.fields.Phrase.value,
    headline: props.fields.Headline.value,
    variant: props.fields['Sign In Presentation'].value,
    returnCodes: props.fields['Return Codes'],
  };
};

export type PageLevelConfig = ReturnType<typeof resolver>;

// TODO maybe we add a useCallback-wrapped useResolver hook at some point?
const SignInPageComponent = (props: any) => {
  const { variant } = props;

  return <SignIn pageLevelConfig={props} variant={variant} />;
};

export default withComponentSetup({
  resolver,
  Component: SignInPageComponent,
});
